<style lang="scss">
.digitales-prospekt {
  .tabelle-box {
    strong {
      padding-left: 0 !important;
    }
    .col-lg-4 {
      @media (max-width: 992px) {
        text-align: right !important;
      }
    }
    .small-text-align-center {
      @media (max-width: 992px) {
        text-align: center !important;
      }
    }
  }
  .tab-group-container {
    margin: 50px 0;
    .title-container {
      .title {
        &:hover {
          background: #eee;
        }
      }
      .active {
      }
    }
  }
  .img-smartphone {
    max-height: 600px;
  }
  .img-link {
    display: block;
  }
}
</style>

<template>
  <div class="digitales-prospekt content container padding-b-m">
    <h1>Digitales Prospekt</h1>
    <p class="intro-txt"><strong>Das Schaufenster im Netz.</strong> Ohne großen Zusatzaufwand und maximal effizient erreichen Sie damit 1,942 Millionen Userinnen und User auf kleinezeitung.at.</p>

    <div class="margin-t-s"><h2>Ihre Werbemöglichkeiten</h2></div>

    <div class="row inner-content">
      <div class="col-lg-6 justify-content-center align-self-center">
        <div class="bild-box">
          <a href="https://digprospekt.smd-digital.at/examples/digi-prospekt-slider/index.html" target="_bank" class="img-link">
            <img src="@/assets/img/img-content/digital/digitales-prospekt.png" alt="Smartphone" class="img-fluid img-smartphone img-s-60 img-center padding-tb-s" />
          </a>
        </div>
        <div class="btn-std btn-bg-blue-dark">
          <a href="https://digprospekt.smd-digital.at/examples/digi-prospekt-slider/index.html" target="_bank" class="img-link">Nutzen Sie Ihr Smartphone und testen Sie das Digitalprospekt.</a>
        </div>
      </div>
      <div class="col-lg-6 justify-content-center align-self-center">
        <h2>Leistungen &amp; Tarif</h2>
        <ul class="content-ul">
          <li>Diese Werbeform ist optimiert für die Anwendung mobiler Endgeräte.</li>
          <li>Der Aufwand ist minimal &ndash; sowohl in der Erstellung als auch in der Pflege.</li>
          <li>Schaffen Sie eigene Themenwelten und spielen Sie die Inhalte zielgruppengenau aus.</li>
        </ul>
        <div class="tabelle-box">
          <div class="row head-row ">
            <div class="col-lg-8 text-align-left hide-small"></div>
            <div class="col-lg-4 text-align-right hide-small">Tarif</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-6 col-8 text-align-left ">Setup (Konvertierung / Erstellung)</div>
            <div class="col-lg-6 col-4 text-align-right">652,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-6 col-8 text-align-left ">TKP</div>
            <div class="col-lg-6 col-4 text-align-right">19,50</div>
          </div>

          <div class="text-center"><div class="info-txt-tabelle ">Buchbar über das Kleine Zeitung-Display-Ad-Tool (Mindestvolumen: 500,00 Euro)</div></div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
      </div>
    </div>

    <!--<div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/beilagen/Digitales-Prospekt-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif - Digitales Prospekt
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>722 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
});
</script>
